import { post, App_ID } from "./publicHeader";

class CreditShop {
  // 积分商城列表
  async getCreditshopList(page, cate, title, credit_start, credit_end,buy_method) {
    try {
      const res = await post("/creditshop/user.Creditshop_Goods/index", {
        app_id: App_ID,
        page,
        cate,
        title,
        credit_start,
        credit_end,
        buy_method
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 积分商品详情信息
  async getCreditshopDetail(id) {
    try {
      const res = await post("/creditshop/user.Creditshop_Goods/detail", {
        app_id: App_ID,
        id,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 提交积分兑换订单信息
  async pushOrder(id, num) {
    try {
      const res = await post("/creditshop/user.creditshop_goods_order/submit_shoporder", {
        app_id: App_ID,
        good_id: id,
        num,
        order_source: 3
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 验证积分商品订单是否可以支付
  async CheckShopOrder(id) {
    try {
      const res = await post("/creditshop/user.pay/pay_shoporder_check", {
        app_id: App_ID,
        id,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 支付积分商品订单
  async payShoporder(id, source) {
    try {
      const res = await post("/creditshop/user.pay/pay_shoporder", {
        app_id: App_ID,
        id,
        source
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  //兑换订单详情
  async getExChangeDetail(id) {
    try {
      const res = await post("/creditshop/user.Creditshop_Goods_Order/detail", {
        app_id: App_ID,
        id,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 获取签到周期
  async getSignCycle() {
    try {
      const res = await post("sign/user.sign/sign_cycle", {
        app_id: App_ID,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 用户签到
  async userCheckIn(day, credit) {
    try {
      const res = await post("/sign/user.sign/sign", {
        app_id: App_ID,
        day,
        credit,
        merch_id: 0,
        store_id: 0,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 买单信息展示
  async offlineShow(qrcode_id, store_id, room_id) {
    try {
      const res = await post("/offlinestore/user.pay/index", {
        app_id: App_ID,
        qrcode_id,
        store_id,
        room_id,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 订单列表
  async oilOrderList(pay_status, page, type, room_id) {
    try {
      const res = await post("/offlinestore/user.order/index", {
        app_id: App_ID,
        pay_status,
        page,
        type,
        room_id,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 订单详情
  async oilOrderDetail(ordersn) {
    try {
      const res = await post("/offlinestore/user.order/detail", {
        app_id: App_ID,
        ordersn,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 优惠券列表
  async getComponList(page) {
    try {
      const res = await post("/coupon/user.coupon/coupon_lists", {
        app_id: App_ID,
        page,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 领取优惠券
  async getCompon(id) {
    try {
      const res = await post("/coupon/user.coupon/receive", {
        app_id: App_ID,
        id,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 我的优惠券列表
  async getScreen(is_used, is_exp, page) {
    try {
      const res = await post("/coupon/user.coupon/index", {
        app_id: App_ID,
        is_used,
        is_exp,
        page,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 支付可用优惠券列表
  async getAvailable(money, status, type, room_id, goods_id) {
    try {
      const res = await post("/coupon/user.coupon/available_coupon", {
        app_id: App_ID,
        money,
        status,
        type,
        room_id,
        goods_id,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 已领取优惠券详情
  async getCpnDetail(id) {
    try {
      const res = await post("/coupon/user.coupon/detail", {
        app_id: App_ID,
        id,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 领券中心详情
  async getCpnCenterDetail(id) {
    try {
      const res = await post("/coupon/user.coupon/detail_", {
        app_id: App_ID,
        id,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 会员卡类别列表
  async vip_cardlist() {
    try {
      const res = await post("/user/user.User_level_card/index", {
        app_id: App_ID,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 后台会员卡设置内容
  async vip_set_detail() {
    try {
      const res = await post("/user/user.Users_Card_Set/detail", {
        app_id: App_ID,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 开通会员卡
  async openVipCard(user_level_id, name, mobile, sex, birthday, password, payment_code, up_grade) {
    try {
      const res = await post("/user/user.User_level_card/open_card", {
        app_id: App_ID,
        user_level_id,
        name,
        mobile,
        sex,
        birthday,
        password,
        payment_code,
        up_grade,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 获取充值金额配置
  async saveMoney() {
    try {
      const res = await post("/offlinestore/user.recharge/config", {
        app_id: App_ID,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 用户微信直冲提交
  async addWeChatMoney(recharge_id, payment_code, receipt_id, type, account_id, key, return_url, source, qrcode_id) {
    try {
      const res = await post("/offlinestore/user.recharge/recharge", {
        app_id: App_ID,
        recharge_id,
        payment_code,
        receipt_id,
        type,
        account_id,
        key,
        return_url,
        source,
        qrcode_id
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 店铺列表
  async shopList(store_id, page) {
    try {
      const res = await post("/offlinestore/user.store/index", {
        app_id: App_ID,
        store_id,
        page,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  async awardDetail(id) {
    try {
      const res = await post("/bigwheel/user.bigwheelorder/detail", {
        app_id: App_ID,
        id,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  async facilityList(page) {
    try {
      const res = await post("/offlinestore/agent.cate/index", {
        app_id: App_ID,
        page,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 扫码支付订单
  async ewmCreateOrder(qrcode_id, room_id, cate_id, money, message, recharge, 
    payment_code, point, pay_password, recharge_id, coupon_id, return_url, receipt_id, account_id, key, num, source, receipt, dis_arr) {
    try {
      const res = await post("/offlinestore/user.pay/createOrder", {
        app_id: App_ID,
        qrcode_id, //二维码id
        room_id,
        cate_id, //设备id
        money,
        message,
        recharge, //	是否充值 1是 0否
        payment_code, // 支付类型
        point, //使用积分 默认0
        pay_password, //支付密码（余额支付需要）
        recharge_id,
        coupon_id, //使用优惠券id
        return_url,
        receipt_id, //发票id
        account_id, //账户id
        key, //充值的index
        coupon_type: 1,
        num, //优惠券使用数量
        source, // 支付环境\
        receipt, // 是否开发票
        dis_arr // 优惠方式
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 刷新二维码
  async refreshQrcode(ordersn, payment_code) {
    try {
      const res = await post("/offlinestore/user.pay/refresh_qrcode", {
        app_id: App_ID,
        ordersn,
        payment_code,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // cartInfor 车辆信息
  async cartInfor() {
    try {
      const res = await post("/user/user.car/index", {
        app_id: App_ID,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  //添加车辆信息
  async addCartInfor(plate_num, oil_type, volume, brand, car_type, buy_time, safe_end) {
    try {
      const res = await post("/user/user.car/saveCar", {
        app_id: App_ID,
        plate_num,
        oil_type,
        volume,
        brand,
        car_type,
        buy_time,
        safe_end,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  //添加开票信息
  async addReceipt(type, tax_name, tax_id, bank, bank_account, mobile, address, is_default) {
    try {
      const res = await post("/user/user.receipt/saveReceipt", {
        app_id: App_ID,
        type,
        tax_name,
        tax_id,
        bank,
        bank_account,
        mobile,
        address,
        is_default,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 发票列表
  async ReceiptList() {
    try {
      const res = await post("/user/user.receipt/getUserReceipt", {
        app_id: App_ID,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // cartInfor 开票信息
  async getReceipt() {
    try {
      const res = await post("/user/user.receipt/index", {
        app_id: App_ID,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 支付方式
  async getPaytype(source) {
    try {
      const res = await post("/offlinestore/user.pay/pay_type", {
        app_id: App_ID,
        source
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 开票信息
  async invoiceIdDetail(id) {
    try {
      const res = await post("/user/user.receipt/detail", {
        app_id: App_ID,
        id,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 删除开票信息
  async removeReceipt(id) {
    try {
      const res = await post("user/user.receipt/removeReceipt", {
        app_id: App_ID,
        id,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 编辑开票信息
  async editReceipt(id, type, tax_name, tax_id, bank, bank_account, mobile, address, is_default) {
    try {
      const res = await post("/user/user.receipt/editReceipt", {
        app_id: App_ID,
        id,
        type,
        tax_name,
        tax_id,
        bank,
        bank_account,
        mobile,
        address,
        is_default,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 获取折扣信息
  async getDiscount(id, cate, goods_id, money) {
    try {
      const res = await post("user/user.User_level_card/preferential", {
        id,
        cate,
        goods_id,
        money
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  // 用户端获取充值设置信息
  async storedInfor(card_type, card_id) {
    try {
      const res = await post("user/user.ConfigRecharge/detail", {
        card_type,
        card_id,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  // 油卡列表
  async payOilsList(type_id, goods_id, room_id) {
    try {
      const res = await post("offlinestore/user.Offline_Type_Card/available", {
        type_id,
        goods_id,
        room_id,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  // 获取积分
  async getUseIntegral(goods_id, amount, payment_code) {
    try {
      const res = await post("user/user.logs/get_point", {
        goods_id,
        amount,
        payment_code,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  // 判断是否有现金订单
  async cash_order_status() {
    try {
      const res = await post("offlinestore/user.order/cash_order_status", {
        status: 1,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  // 删除现金订单
  async cashOrder_delete() {
    try {
      const res = await post("offlinestore/user.order/order_delete", {});
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  //切换账号的列表
  async getAccountList() {
    try {
      const res = await post("wechat/user.account/accountList");
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  //切换账号
  async changeAccount(mobile) {
    try {
      const res = await post("wechat/user.account/clickAccount", {
        mobile,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  //切换&添加账号
  async changeAddAccount() {
    try {
      const res = await post("wechat/user.account/changeAddAccount");
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  //发送校验验证码
  async send_sms() {
    try {
      const res = await post("user/user.index/send_sms");
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  //验证手机号正确
  async verify_mobile(verification_code) {
    try {
      const res = await post("user/user.index/verify_mobile", {
        verification_code,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  //申请开卡
  async apply_for_card(name, contact_name, mobile, estimated_amount, id) {
    try {
      const res = await post("teamcard/user.Team_Card_Apply/apply", {
        name,
        contact_name,
        mobile,
        estimated_amount,
        id,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  //后台是否开启申请
  async is_teamcard() {
    try {
      const res = await post("teamcard/user.Team_Card_Apply/is_teamcard");
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  //后台是否开启申请
  async team_carddetail() {
    try {
      const res = await post("teamcard/user.Team_Card_Apply/detail");
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  //发卡列表
  async send_card_list() {
    try {
      const res = await post("teamcard/user.Team_Card/team_cards", {
        card_id: 0,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  //发卡详情
  async Team_Card_detial(id) {
    try {
      const res = await post("teamcard/user.Team_Card/detail", {
        id,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  //修改限制
  async Team_Card_edit(id, num_limit_amount, day_limit_amount, month_limit_amount, day_limit_num, password, is_pass) {
    try {
      const res = await post("teamcard/user.Team_Card/edit", {
        id,
        num_limit_amount,
        day_limit_amount,
        month_limit_amount,
        day_limit_num,
        password,
        is_pass,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  //发卡
  async team_send_card(uid, is_pass, password, num_limit_amount, day_limit_amount, month_limit_amount, day_limit_num, balance, mobile, car_num, contact_name, card_id) {
    try {
      const res = await post("teamcard/user.Team_Card/send_card", {
        is_pass,
        password,
        num_limit_amount,
        day_limit_amount,
        month_limit_amount,
        day_limit_num,
        balance,
        mobile,
        car_num,
        contact_name,
        card_id,
        uid,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  //司机列表
  async driver_list(team_id, card_id, mobile, sort) {
    try {
      const res = await post("teamcard/user.Team_Card/index", { team_id, card_id, mobile, sort });
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  //个人拥有车队卡列表
  async myTeamCards() {
    try {
      const res = await post("teamcard/user.Team_Card/my_team_cards");
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  //可用支付车队卡列表
  async TeamCardsPay(type_id, goods_id, room_id, amount) {
    try {
      const res = await post("teamcard/user.Team_Card/available", { type_id, goods_id, room_id, amount });
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  //可用支付车队卡列表
  async validate_pass(account_id) {
    try {
      const res = await post("teamcard/user.Team_Card/validate_pass", { account_id });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  //调整额度
  async updateTeamPrice(account_id, amount, type) {
    try {
      const res = await post("teamcard/user.Team_Card/lifting_amount", { account_id, amount, type });
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  // 车队子卡消费记录
  async consume_record(account_id, start_time, end_time, page) {
    try {
      const res = await post("teamcard/user.Team_Card/consume_lists", { account_id, start_time, end_time, page });
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  // 车队卡消费记录
  async team_consume_record(account_id, start_time, end_time, page) {
    try {
      const res = await post("teamcard/user.Team_Card/team_balance_lists", { account_id, start_time, end_time, page });
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  // 解绑车队卡
  async del_team_card(id) {
    try {
      const res = await post("teamcard/user.Team_Card/del", { id });
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  //开通亲情卡
  async openFamily_card(relation, mobile, account_type, account_id, quota) {
    try {
      const res = await post("user/user.family_card/openCard", { relation, mobile, account_type, account_id, quota });
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  //为亲属开通的卡列表
  async familyCardList() {
    try {
      const res = await post("user/user.family_card/familyCard");
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  //个人账户列表
  async familyaccount() {
    try {
      const res = await post("user/user.family_card/account");
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  //解绑
  async delCard(card_id) {
    try {
      const res = await post("user/user.family_card/delCard", { card_id });
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  //调整额度
  async updateQuota(card_id, quota) {
    try {
      const res = await post("user/user.family_card/updateQuota", { card_id, quota });
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  //支付方式可使用的家庭卡
  async Pay_family_card(amount, merch_id, store_id, room_id, type_id, goods_id) {
    try {
      const res = await post("user/user.family_card/available", { amount, merch_id, store_id, room_id, type_id, goods_id });
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  //家人为我开通的亲属卡
  async myFamilyCard() {
    try {
      const res = await post("user/user.family_card/myFamilyCard");
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  //亲属卡消费记录
  async FamilyCardRecord(card_id, page, start_time, end_time) {
    try {
      const res = await post("user/user.family_card/customList", {
        card_id,
        page,
        limit: 10,
        start_time,
        end_time,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  // 获取积分商品分类列表
  async getCustomList(data) {
    try {
      const res = await post("/creditshop/user.cate/index", data);
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  // 积分商品订单验证
  async checkShopOrder(data) {
    try {
      const res = await post("/creditshop/user.pay/pay_shoporder_check", data);
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  // 支付积分商品订单
  async payShopOrder(data) {
    try {
      const res = await post("/creditshop/user.pay/pay_shoporder", data);
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  
  //判断系统更新
  async systemUpdate () {
    try {
      const res = await post("api/test/stop");
      return res;
    } catch (error) {
      console.log(error);
    }
  }
}

export default new CreditShop();
